import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeasurementChecklist = _resolveComponent("MeasurementChecklist")!
  const _component_AddMeasurements = _resolveComponent("AddMeasurements")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: "",
        ref: "content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["container max-w-2xl mx-auto min-h-full", { 'flex items-center justify-center': !_ctx.showMeasurements }])
          }, [
            (!_ctx.showMeasurements)
              ? (_openBlock(), _createBlock(_component_MeasurementChecklist, {
                  key: 0,
                  onReady: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMeasurements = !_ctx.showMeasurements))
                }))
              : (_openBlock(), _createBlock(_component_AddMeasurements, { key: 1 }))
          ], 2)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}