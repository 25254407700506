
import MeasurementChecklistItem from '@/components/Card/MeasurementChecklistItem.vue';
import { IonButton } from '@ionic/vue';

export default {
  name: 'MeasurementChecklist',
  components: {
    MeasurementChecklistItem,
    IonButton
  }
};
