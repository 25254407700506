import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, { lines: "full" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, { class: "py-1 capitalize" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_select, {
          name: _ctx.id,
          interface: "action-sheet",
          "interface-options": _ctx.customActionSheetOptions,
          placeholder: "Select",
          value: _ctx.modelValue,
          onIonFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFocus', $event))),
          onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, ({ value, label }) => {
              return (_openBlock(), _createBlock(_component_ion_select_option, {
                key: value,
                value: value,
                "data-label": label
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(label), 1)
                ]),
                _: 2
              }, 1032, ["value", "data-label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["name", "interface-options", "value"])
      ]),
      _: 1
    }),
    (_ctx.errors[0])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.errors[0]), 1))
      : _createCommentVNode("", true)
  ], 64))
}