import AWS from 'aws-sdk/global';
import Kinesis from 'aws-sdk/clients/kinesis';
import * as Sentry from '@sentry/browser';
import { v4 } from 'uuid';
import { store } from '@/store';

export const recordPatientEvent = (event: string): void => {
  AWS.config.getCredentials((err, credentials) => {
    if (err) {
      Sentry.captureException(err);
      return;
    }

    if (!store.state.currentUser?.id) {
      Sentry.captureMessage('Tried to record analytics event without a current user in the store');
      return;
    }

    const identityId = credentials instanceof AWS.CognitoIdentityCredentials ? credentials.identityId : '';
    const streamName = process.env.VUE_APP_AWS_KINESIS_STREAM_NAME ?? '';

    const kinesis = new Kinesis({ apiVersion: '2013-12-02' });
    kinesis.putRecord(
      {
        Data: JSON.stringify({
          environment: process.env.VUE_APP_LOG_ENV,
          date_time: new Date(),
          user_id: store.state.currentUser?.id,
          patient_id: store.state.currentUser?.id,
          event_id: v4(),
          event_action: event,
          pathway: 'COPD'
        }),
        PartitionKey: `partition-${identityId}`,
        StreamName: streamName
      },
      (error) => {
        Sentry.captureException(error);
      }
    );
  });
};
