import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ae16d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "-mt-1 text-xs leading-none text-gray-400 block"
}
const _hoisted_3 = {
  key: 0,
  class: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.icon,
                class: "mr-4",
                role: "presentation",
                alt: ""
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, {
            class: _normalizeClass(["capitalize", _ctx.optional ? 'py-0 leading-relaxed' : 'py-1']),
            "aria-hidden": "true"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${_ctx.label} (${_ctx.unit})`) + " ", 1),
              (_ctx.optional)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Optional"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_input, {
            class: "text-right",
            name: _ctx.id,
            value: _ctx.modelValue,
            type: "number",
            placeholder: "0",
            inputmode: "numeric",
            onIonFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFocus', $event))),
            onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 8, ["name", "value"])
        ]),
        _: 1
      }),
      (_ctx.errors && _ctx.errors.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errors[0]), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}