<template>
  <BaseCard class="py-6">
    <ion-item lines="none">
      <img :src="checkSquareIcon" class="ml-2 mr-6 text-5xl" role="presentation" alt="" />
      <ion-label class="text-gray-900 my-0">
        <span class="text-base whitespace-normal"><slot /></span>
      </ion-label>
    </ion-item>
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/Card/BaseCard';
import { IonItem, IonLabel } from '@ionic/vue';
import checkSquareIcon from '@resources/icons/check-square.svg';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MeasurementChecklistItem',
  components: {
    BaseCard,
    IonLabel,
    IonItem
  },
  setup() {
    return {
      checkSquareIcon
    };
  }
});
</script>
