
import { IonContent, IonPage, onIonViewWillEnter, onIonViewDidEnter } from '@ionic/vue';
import AddMeasurements from '@/views/AddMeasurements.vue';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { isDischargedCheck } from '@/helpers/discharge.helper';
import throttle from 'lodash-es/throttle';
import MeasurementChecklist from '@/views/MeasurementsChecklist.vue';
import { ComponentPublicInstance } from '@vue/runtime-core';

export default defineComponent({
  name: 'MeasurementPage',
  components: {
    AddMeasurements,
    MeasurementChecklist,
    IonPage,
    IonContent
  },
  setup() {
    const router = useRouter();

    const showMeasurements = ref<boolean>(false);
    const content = ref<ComponentPublicInstance>();

    const checkDischargeStatus = throttle(async () => {
      const isDischarged: boolean = await isDischargedCheck();
      if (isDischarged) {
        await router.push('/discharged');
      }
    }, 30000);

    const scrollToTop = () => {
      content.value?.$el.scrollToPoint(0, 0, 0);
    };

    onIonViewWillEnter(() => {
      showMeasurements.value = false;
      checkDischargeStatus();
    });

    onIonViewDidEnter(() => scrollToTop());

    watch(showMeasurements, scrollToTop);

    return {
      checkDischargeStatus,
      showMeasurements,
      content
    };
  }
});
