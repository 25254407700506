
import { IonSelect, IonItem, IonLabel, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';

interface ActionSheetOption {
  header: string;
}
export default defineComponent({
  name: 'SelectOptionsWithValue',
  components: {
    IonSelect,
    IonSelectOption,
    IonItem,
    IonLabel
  },
  emits: ['onFocus', 'update:modelValue'],
  props: {
    modelValue: {
      type: [Object, String]
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  setup(props: any) {
    const customActionSheetOptions: ActionSheetOption = {
      header: props.label
    };

    return {
      customActionSheetOptions
    };
  }
});
